import { config as embeddedProductionConfig } from "./embedded-production";
import { config as embeddedLocalConfig } from "./embedded-local";
import { config as localConfig } from "./local";
import { config as productionConfig } from "./production";
let config;
switch (process.env.CONCENTRIC_ENVIRONMENT) {
    case "production":
        config = productionConfig;
        break;
    case "embedded-production":
        config = embeddedProductionConfig;
        break;
    case "embedded-local":
        config = embeddedLocalConfig;
        break;
    default:
        config = localConfig;
}
console.log(`using ${config.name} config`);
export default config;
