export function getWordAt(value, caretPosition) {
    let endOffset = value.indexOf(" ", caretPosition);
    if (endOffset === -1) {
        endOffset = value.length;
    }
    const result = /\S+$/.exec(value.slice(0, endOffset));
    const word = result ? result[0] : "";
    return {
        startOffset: endOffset - word.length,
        endOffset,
        word,
    };
}
export function replaceWordInString({ startOffset, endOffset, newWord, original }) {
    const newString = original.slice(0, startOffset) + newWord + original.slice(endOffset);
    return newString;
}
