import { writable } from "svelte/store";
const OAUTH_SECRET_KEY = "oauthSecret";
const ACCESS_TOKEN_KEY = "accessToken";
const ACCESS_TOKEN_SECRET_KEY = "accessTokenSecret";
const DRAFT_MESSAGES_KEY = "draft_messages";
const PENDING_MESSAGES_KEY = "pending_messages";
const SENT_MESSAGES_KEY = "num_sent_messages";
const NAME_KEY = "name";
const PROFILE_IMAGE_KEY = "profileImage";
const SCREEN_NAME_KEY = "screenName";
const USER_ID_KEY = "userId";
function createWriteableInteger(key) {
    let storedValueString = localStorage.getItem(key);
    if (storedValueString === "null") {
        storedValueString = "0";
    }
    const storedValue = Number.parseInt(storedValueString, 10);
    const value = writable(storedValue);
    value.subscribe(newValue => {
        localStorage.setItem(key, newValue.toString());
    });
    return value;
}
function createWriteableString(key) {
    let storedValue = localStorage.getItem(key);
    if (storedValue === "null") {
        storedValue = "";
    }
    const value = writable(storedValue);
    value.subscribe(newValue => {
        localStorage.setItem(key, newValue);
    });
    return value;
}
function createWriteableArray(key) {
    let storedValueString = localStorage.getItem(key);
    if (storedValueString === "null") {
        storedValueString = "[]";
    }
    const storedValue = JSON.parse(storedValueString);
    const value = writable(storedValue);
    value.subscribe(newValue => {
        localStorage.setItem(key, JSON.stringify(newValue));
    });
    return value;
}
export const OAuthSecret = createWriteableString(OAUTH_SECRET_KEY);
export const AccessToken = createWriteableString(ACCESS_TOKEN_KEY);
export const AccessTokenSecret = createWriteableString(ACCESS_TOKEN_SECRET_KEY);
export const Name = createWriteableString(NAME_KEY);
export const DraftMessages = createWriteableArray(DRAFT_MESSAGES_KEY);
export const PendingMessages = createWriteableArray(PENDING_MESSAGES_KEY);
export const SentMessages = createWriteableInteger(SENT_MESSAGES_KEY);
export const ProfileImageUrl = createWriteableString(PROFILE_IMAGE_KEY);
export const ScreenName = createWriteableString(SCREEN_NAME_KEY);
export const UserId = createWriteableString(USER_ID_KEY);
