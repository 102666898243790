<script lang="ts">
  import { login } from "../services/twitter";

  let loginPressed = false;
  function handleLogin() {
    loginPressed = true;
    login();
  }
</script>

<main>
  <div class="icon-container">
    <div class="icon">
      <img src="assets/app-icon-dark.png" alt="logo" />
    </div>
    <span>Concentric</span>
  </div>
  <div class="guru">
    <img class="main-image" src="assets/meditation.png" alt="guru" />
    <img class="spinning" src="assets/twitter.png" alt="twitter" />
  </div>
  <div class="highlights">
    <p>
      <img src="assets/meditation.png" alt="Meditation Icon" />  Compose your tweets without distractions, even offline!
    </p>
    <p>✒️ Write a whole thread at once.</p>
    <p>📱 Web, mobile and desktop apps</p>
    <p>🧭 Other microblogging platforms coming soon!</p>
  </div>
  <div class="button-container">
    <button disabled={loginPressed} on:click={handleLogin}>Log in with Twitter</button>
  </div>
</main>

<style lang="scss">
  :root {
    --guru-width: 40vh;
    --spinner-x: 27vh;
    --spinner-top: 26vh;
  }

  main {
    text-align: center;
    padding: 1em;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
  }

  .icon-container {
    position: fixed;
    top: 20px;
    left: 20px;
    height: 50px;
    display: flex;
    align-items: center;
  }

  .icon {
    transform: rotate3d(0, 0, 0, 0deg);
    box-shadow: 2em 4em 6em -2em rgba(0, 0, 0, 0.5), 1em 2em 3.5em -2.5em rgba(0, 0, 0, 0.5);
    transition: transform 0.4s ease, box-shadow 0.4s ease;
    border-radius: 0.5em;

    &:hover {
      transform: rotate3d(0.5, -0.866, 0, 30deg) rotate(1deg);
    }

    img {
      width: 50px;
      height: 50px;
    }
  }

  .guru {
    width: var(--guru-width);
    height: var(--guru-width);
    margin-top: 3em;
  }

  .main-image {
    width: 100%;
    height: 100%;
  }

  .spinning {
    position: absolute;
    left: 47vw;
    top: var(--spinner-top);
    width: 3em;
    height: 3em;
    transform-origin: center center;
    -webkit-animation: rotation 4s linear infinite; /* Chrome, Safari 5 */
    -moz-animation: rotation 4s linear infinite; /* Firefox 5-15 */
    -o-animation: rotation 4s linear infinite; /* Opera 12+ */
    animation: rotation 4s linear infinite; /* Chrome, Firefox 16+,
                                                      IE 10+, Safari 5 */
  }

  /*
  * Set up the keyframes to actually describe the begining and end states of
  * the animation.  The browser will interpolate all the frames between these
  * points.  Again, remember your vendor-specific prefixes for now!
  */
  @-webkit-keyframes rotation {
    from {
      -webkit-transform: rotate(0deg) translateX(var(--spinner-x)) rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg) translateX(var(--spinner-x)) rotate(-360deg);
    }
  }

  @-moz-keyframes rotation {
    from {
      -moz-transform: rotate(0deg) translateX(var(--spinner-x)) rotate(0deg);
    }
    to {
      -moz-transform: rotate(360deg) translateX(var(--spinner-x)) rotate(-360deg);
    }
  }

  @-o-keyframes rotation {
    from {
      -o-transform: rotate(0deg) translateX(var(--spinner-x)) rotate(0deg);
    }
    to {
      -o-transform: rotate(360deg) translateX(var(--spinner-x)) rotate(-360deg);
    }
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg) translateX(var(--spinner-x)) rotate(0deg);
    }
    to {
      transform: rotate(360deg) translateX(var(--spinner-x)) rotate(-360deg);
    }
  }

  .highlights {
    margin-top: 7vh;

    p {
      vertical-align: middle;
    }

    img {
      width: 1.2em;
      height: 1.2em;
      vertical-align: middle;
    }
  }

  p {
    font-size: small;
  }

  .button-container {
    margin-top: 10vh;
  }

  button {
    width: 10em;
    height: 2em;
    margin-bottom: 5px;
    padding: 0;
    border-radius: 5px;
    background-color: #1da1f2;
    font-weight: bold;
    color: white;
    border: 0;
  }

  button:disabled {
    background-color: white;
    border: 2px solid grey;
    color: lightgray;
    cursor: default;
  }
</style>
