<script lang="ts">
  import { createForm } from "svelte-forms-lib";
  import { nanoid } from 'nanoid';
  import Composer from "./TweetComposer.svelte";
  import { PendingMessages, DraftMessages } from "../services/store";
  import { playScribble } from "../services/audio";

  function addNewThread() {
    const firstMessage = $DraftMessages[0];
    let inReplyToInternalId: string | undefined;
    if (firstMessage) {
      inReplyToInternalId = firstMessage.internalId;
    }
    $DraftMessages = [
      ...$DraftMessages,
      {
        internalId: `internal_${nanoid()}`,
        text: "",
        inReplyToInternalId,
      }
    ]
  }

  if ($DraftMessages.length === 0) {
    addNewThread();
  }

  function postTweet() {
    $PendingMessages = [
      ...$PendingMessages,
      ...$DraftMessages.filter(t => t.text.trim().length),
    ];
    $DraftMessages = [];
    addNewThread();
  }

  const { form, handleChange, handleSubmit } = createForm({
    initialValues: {
    },
    onSubmit: () => {
      playScribble();
      postTweet();
    },
  });
</script>

<main>
  <form on:submit={handleSubmit}>
    <section class="top">
      <div>
        <button type="button" title="Add to thread" on:click={addNewThread}>+</button>
      </div>
    </section>

    {#each $DraftMessages as message (message.internalId)}
      <Composer
        on:change={handleChange}
        messageIndex={$DraftMessages.findIndex(m => m.internalId === message.internalId)}
      />
    {/each}

    <section class="bottom">
      <div>
        <button
          type="submit"
          title="Send Tweet"
          disabled={!$DraftMessages.some(t => t.text.trim().length)}
        >
          Tweet
        </button>
      </div>
    </section>
  </form>
</main>

<style>
  main {
    text-align: center;
    padding: 1em;
    margin: 0 auto;
  }

  form {
    display: flex;
    flex-direction: column;
  }

  button {
    flex: 1;
    width: 4em;
    height: 2em;
    margin-bottom: 5px;
    padding: 0;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
  }

  button[type="submit"] {
    background-color: #1da1f2;
    font-size: large;
    color: white;
    border: 0;
  }

  button[type="submit"]:disabled {
    background-color: white;
    border: 2px solid grey;
    color: lightgray;
    cursor: default;
  }

  section {
    flex-direction: row;
    display: flex;
  }

  .top {
    justify-content: flex-start;
  }

  .bottom {
    justify-content: flex-end;
  }

  @media (min-width: 640px) {
    main {
      max-width: none;
    }
  }
</style>
