import { nanoid } from 'nanoid';
import { callAPI } from "./api";
import { PendingMessages } from "../store";
export async function postTweet(requestBody) {
    if (process.env.DISABLE_TWEETS) {
        return {
            id_str: nanoid(),
            internalId: requestBody.tweet.internalId,
        };
    }
    const response = await callAPI("/post-tweet", { method: "POST" }, requestBody);
    return response;
}
export function startSending(accessToken, accessTokenSecret) {
    return PendingMessages.subscribe(async (pendingMessages) => {
        // Always deal with the first message in the list
        const message = pendingMessages[0];
        if (message) {
            const newMessage = await postTweet({
                accessToken,
                accessTokenSecret,
                tweet: message,
            });
            console.info(`sent ${newMessage.internalId} -> ${newMessage.id_str}`);
            const newPendingMessages = pendingMessages.filter((m) => m.internalId !== message.internalId);
            // In theory id_str can be null if there was an error sending
            if (newMessage.id_str) {
                newPendingMessages.forEach((m) => {
                    if (m.inReplyToInternalId === message.internalId) {
                        // eslint-disable-next-line no-param-reassign
                        m.inReplyToStatusId = newMessage.id_str;
                    }
                });
            }
            else {
                console.error("New message has no ID... usually this means it was a duplicate");
            }
            PendingMessages.set(newPendingMessages);
        }
    });
}
