<script type="ts">
  import { DraftMessages } from "../services/store";
</script>

{#if $DraftMessages.some((m) => m.text.trim().length > 0)}
<div>
  <img src="assets/content-save.png" alt="saved">
  <span>Draft saved!</span>
</div>
{/if}

<style type="scss">
  div {
    display: flex;
    align-items: center;
    font-size: small;
    color: gray;
  }

  img {
    width: 1.5em;
    height: 1.5em;
  }

  span {
    margin-left: 5px;
  }
</style>
