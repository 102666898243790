import { AccessToken, AccessTokenSecret, ScreenName, UserId } from "../store";
import { callAPI } from "./api";
export async function handleRedirect({ oauthToken, oauthVerifier }) {
    const requestBody = {
        oauthToken,
        oauthVerifier,
    };
    try {
        const responseBody = await callAPI("/get-access-token", {
            method: "POST",
        }, requestBody);
        AccessToken.set(responseBody.accessToken);
        AccessTokenSecret.set(responseBody.accessTokenSecret);
        ScreenName.set(responseBody.screenName);
        UserId.set(responseBody.userId);
    }
    catch (err) {
        window.location.replace("/");
    }
}
