<script lang="ts">
  import {
    AccessToken,
    AccessTokenSecret,
    PendingMessages
  } from "../services/store";
  import InternetConnection from "svelte-internet-connection";

  import { startSending } from "../services/twitter";
import SaveIcon from "./SaveIcon.svelte";
  let unsubscribe = startSending($AccessToken, $AccessTokenSecret);
  function handleConnectionChange() {
    unsubscribe();
    unsubscribe = startSending($AccessToken, $AccessTokenSecret);
  }
</script>

<div class="container">
  <div class="image-container">
    {#if $PendingMessages.length === 0}
      <img src="assets/app-icon-dark.png" alt="No pending" />
    {:else}
      <img src="assets/twitter.png" alt="pending" />
    {/if}
  </div>
  <SaveIcon />
  {#if $PendingMessages.length > 0}
    <span>{$PendingMessages.length}</span>
  {/if}
  <InternetConnection on:change={handleConnectionChange} let:offline>
    {#if offline}
      <p class="offline">
        You are offline, but you can still send messages for later...
      </p>
    {/if}
  </InternetConnection>
</div>

<style type="scss">
  .container {
    position: fixed;
    top: 5px;
    left: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    height: 1.8em;
  }

  .image-container {
    transform: rotate3d(0, 0, 0, 0deg);
    box-shadow: 2em 4em 6em -2em rgba(0, 0, 0, 0.5),
      1em 2em 3.5em -2.5em rgba(0, 0, 0, 0.5);
    transition: transform 0.4s ease, box-shadow 0.4s ease;
    border-radius: 0.5em;

    &:hover {
      transform: rotate3d(0.5, -0.866, 0, 30deg) rotate(1deg);
    }
  }

  img {
    width: 2em;
    height: 2em;
  }

  .offline {
    margin-left: 1em;
    font-size: small;
    color: red;
  }
</style>
