import { callAPI } from "./api";
export async function searchUsers({ accessToken, accessTokenSecret, q, }) {
    const requestBody = {
        accessToken,
        accessTokenSecret,
        q,
    };
    const responseBody = await callAPI("/search-users", {
        method: "POST",
        notoast: true,
    }, requestBody);
    return responseBody;
}
