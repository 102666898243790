<script lang="ts">
  import queryString from "query-string";
  import { getUser, handleRedirect } from "../services/twitter";
  import {
    UserId,
    Name,
    ScreenName,
    ProfileImageUrl,
    AccessToken,
    AccessTokenSecret
  } from "../services/store";
import { signOut } from "../services/twitter/sign-out";

  const qs = queryString.parse(window.location.search);
  const oauthToken = qs["oauth_token"];
  const oauthVerifier = qs["oauth_verifier"];
  if (Array.isArray(oauthToken)) {
    console.error("oauthToken should not be an array");
  }

  if (Array.isArray(oauthVerifier)) {
    console.error("oauthVerifier should not be an array");
  }

  if (oauthToken) {
    const input = {
      oauthToken: oauthToken as string,
      oauthVerifier: oauthVerifier as string
    };
    handleRedirect(input);
  }

  $: if ($UserId) {
    getUser({
      accessToken: $AccessToken,
      accessTokenSecret: $AccessTokenSecret,
      userId: $UserId
    });
  }
</script>

<div class="container">
  {#if !$ProfileImageUrl && oauthToken}
    <p>Logging you in...</p>
  {:else}
    <img src={$ProfileImageUrl} alt="profile" />
    <div class="name-container">
      <p class="name">{$Name}</p>
      <p class="screen-name">@{$ScreenName}</p>
      <!-- svelte-ignore a11y-missing-attribute -->
      <a class="sign-out" role="button" on:click={signOut}>Sign out</a>
    </div>
  {/if}
</div>

<style>
  .container {
    position: fixed;
    top: 5px;
    right: 5px;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
  }
  img {
    width: 2em;
    border-radius: 50%;
  }
  .name-container {
    height: 1.5em;
  }

  p {
    line-height: 1.2em;
    margin: 0;
  }

  .name {
    font-size: small;
  }
  .screen-name {
    color: gray;
    font-size: x-small;
  }

  .sign-out {
    font-size: x-small;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }
</style>
