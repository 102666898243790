<script lang="ts">
  import Rollbar from "rollbar";
  import { onMount, setContext } from "svelte";
  import { contextKey } from "./utils/errors";
  import config from "./config";

  const rollbar = new Rollbar({
    accessToken: "4940f35943214b7599dda34d90b7cdab",
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: config.name,
    },
  });

  function logError(...args: Rollbar.LogArgument[]) {
    rollbar.error(args);
  }

  setContext(contextKey, {
    logError,
  });

  onMount(() => {
    window.onunhandledrejection = (e) => {
      logError("we got exception, but the app has crashed", e);
    };
  });
</script>

<slot/>
