import { Name, ProfileImageUrl } from "../store";
import { callAPI } from "./api";
export async function getUser({ accessToken, accessTokenSecret, userId, }) {
    const requestBody = {
        accessToken,
        accessTokenSecret,
        userId,
    };
    const responseBody = await callAPI("/get-user", {
        method: "POST",
    }, requestBody);
    Name.set(responseBody.name);
    ProfileImageUrl.set(responseBody.profileImageUrl);
}
