<script type="ts">
  import { createEventDispatcher } from "svelte";
  import type { SearchUserResponseBody } from "../../shared-types";
  import debounce from "lodash.debounce";
  import { searchUsers } from "../services/twitter/search-users";
  import { AccessToken, AccessTokenSecret } from "../services/store";

  const dispatch = createEventDispatcher();
  export let top = 0;
  export let left = 0;
  export let query = "";
  let list: SearchUserResponseBody = [];
  let loading = false;
  async function onChangeQuery(q: string): Promise<void> {
    if (q.trim().length > 0) {
      const request = {
        accessToken: $AccessToken,
        accessTokenSecret: $AccessTokenSecret,
        q,
      };
      loading = true;
      try {
        const response = await searchUsers(request);
        list = response;
      } catch (err) {
        console.error(err);
      } finally {
        loading = false;
      }
    }
  }
  const debouncedQuery = debounce(onChangeQuery, 300);

  $: {
    debouncedQuery(query);
  }

  function onSelect(screenName: string) {
    dispatch("select", {
      screenName,
    });
  }
</script>

<div
  class="container"
  style={`
  top: ${top}px;
  left: ${left}px;
`}
>
  {#if loading}
    <div class="item">🏄</div>
  {:else}
    {#each list as item}
      <div
        class="item"
        on:click={(event) => {
          event.stopPropagation();
          onSelect(item.screenName);
        }}
      >
        <img src={item.profileImageUrl} alt={item.name} />
        <div>
          <p class="name">{item.name}</p>
          <p class="screen-name">@{item.screenName}</p>
        </div>
      </div>
    {/each}
  {/if}
</div>

<style type="scss">
  .container {
    position: fixed;
    z-index: 2;
    width: 20em;
    min-height: 3em;
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 1px dashed #222222;
    border-radius: 2px;
  }

  .item {
    height: 3em;
    font-size: small;
    display: flex;
    cursor: pointer;

    &:hover {
      background-color: #dddddd;
    }

    p {
      margin: 0;
    }

    .screen-name {
      color: gray;
      font-size: x-small;
    }

    img {
      width: 2.5em;
      height: 2.5em;
      border-radius: 1.25em;
      margin-right: 0.5em;
      align-self: flex-start;
    }
  }
</style>
