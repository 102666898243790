<script lang="ts">
  import type { SelectMentionEvent, SelectTrendEvent } from "../events";

  import { DraftMessages } from "../services/store";
  import { getWordAt, replaceWordInString } from "../utils/strings";
  import type { Coords } from "../utils/textarea";
  import { getCaretCoordinates, getCaretPositionInString } from "../utils/textarea";
  import MentionBox from "./MentionBox.svelte";
  import TrendsBox from "./TrendsBox.svelte";

  export let messageIndex: number;
  let showTrendsBox = false;
  let showMentionBox = false;
  let mentionText = "";
  let mentionLeft = 0;
  let mentionTop = 0;
  let caretPosition = 0;

  function adjustPosition(position: Coords): Coords {
    if (!position) {
      return {
        x: 0,
        y: 0,
      }
    }
    let { x, y } = position;
    y = y + 20;
    if (x + 300 >= window.innerWidth) {
      x = 40;
    }
    return { x, y };
  }

  function handleKeyup(event: KeyboardEvent) {
    const textarea = event.target as HTMLDivElement;
    const offset = getCaretPositionInString();
    caretPosition = offset;
    const { word } = getWordAt(textarea.textContent, offset);
    const position = getCaretCoordinates();
    showMentionBox = word.startsWith("@");
    showTrendsBox = word.startsWith("#");
    mentionText = word.substring(1);
    const adjustedPosition = adjustPosition(position);
    mentionLeft = adjustedPosition.x;
    mentionTop = adjustedPosition.y;
  }

  function handleBlurTextarea(event: FocusEvent) {
    const textarea = event.target as HTMLDivElement;
    if (!textarea.textContent.trim().length) {
      textarea.textContent = "";
    }
    setTimeout(() => {
      showMentionBox = false;
      mentionText = "";
    }, 100);
  }

  function handleKeypress(event: KeyboardEvent) {
    handleKeyup(event);
    handleChange();
  }

  function handleChange() {
    const message = $DraftMessages[messageIndex];
    const count = 280 - message.text.length;
    if (count <= 0) {
      $DraftMessages[messageIndex] = {
        ...message,
        text: $DraftMessages[messageIndex].text.substring(0, 279),
      };
      return false;
    }
  }

  function handleDelete() {
    const internalId = $DraftMessages[messageIndex].internalId;
    DraftMessages.update((value) => value.filter(m => m.internalId !== internalId));
  }

  function onSelectMention({ detail: { screenName } }: CustomEvent<SelectMentionEvent>) {
    showMentionBox = false;
    mentionText = "";
    const original = $DraftMessages[messageIndex].text;
    const { startOffset, endOffset } = getWordAt(original, caretPosition);
    const newContent = replaceWordInString({
      startOffset,
      endOffset,
      newWord: `@${screenName}`,
      original,
    });
    $DraftMessages[messageIndex].text = newContent;
  }

  function onSelectTrend({ detail: { name } }: CustomEvent<SelectTrendEvent>) {
    showTrendsBox = false;
    mentionText = "";
    const original = $DraftMessages[messageIndex].text;
    const { startOffset, endOffset } = getWordAt(original, caretPosition);
    const newContent = replaceWordInString({
      startOffset,
      endOffset,
      newWord: `${name}`,
      original,
    });
    $DraftMessages[messageIndex].text = newContent;
  }
</script>

<div class="container">
  <div
    name="tweet"
    class="textarea"
    contenteditable
    bind:textContent={$DraftMessages[messageIndex].text}
    placeholder="What's on your mind?"
    on:blur={handleBlurTextarea}
    on:paste={handleChange}
    on:keyup={handleKeypress}
    on:change={handleChange}
  />
  {#if messageIndex > 0}
    <div class="delete" on:click={handleDelete}>
      <img src="assets/delete.png" alt="delete"/>
    </div>
  {/if}
  <div class="counter">
    <span>{280 - $DraftMessages[messageIndex].text.length} / 280</span>
  </div>
  {#if showMentionBox}
    <MentionBox
      left={mentionLeft}
      top={mentionTop}
      query={mentionText}
      on:select={onSelectMention}
    />
  {/if}
  {#if showTrendsBox}
    <TrendsBox
      left={mentionLeft}
      top={mentionTop}
      query={mentionText}
      on:select={onSelectTrend}
    />
  {/if}
</div>

<style>
  .container {
    position: relative;
    height: 9em;
  }

  .textarea {
    width: 100%;
    min-height: 7.5em;
    height: 7.5em;
    resize: none;
    text-align: start;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: large;
    border: 1px gray solid;
    border-radius: 5px;
  }

  .textarea:focus {
    outline: 1px solid #1da1f2;
  }

  [placeholder]:empty::before {
    content: attr(placeholder);
    color: rgb(141, 141, 141);
  }

  [placeholder]:empty:focus::before {
    content: "";
  }

  .delete {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
  }

  .counter {
    position: absolute;
    bottom: 0.5em;
    right: 0;
  }

  span {
    font-size: smaller;
    text-align: right;
  }
</style>
