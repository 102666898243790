import { toast } from '@zerodevx/svelte-toast';
import config from "../../config";
import { callAPI } from "./api";
import { OAuthSecret } from "../store";
export async function login() {
    const requestBody = {
        callbackUrl: config.twitterCallback,
    };
    const responseBody = await callAPI("/login", {
        method: "POST",
    }, requestBody);
    const { oauthToken, oauthTokenSecret } = responseBody;
    OAuthSecret.set(oauthTokenSecret);
    try {
        const url = `https://api.twitter.com/oauth/authenticate?oauth_token=${oauthToken}`;
        window.location.assign(url);
    }
    catch (err) {
        toast.push(err.message);
    }
}
