import { AccessToken, AccessTokenSecret, DraftMessages, Name, OAuthSecret, PendingMessages, ProfileImageUrl, ScreenName, SentMessages, UserId, } from "../store";
export function signOut() {
    // eslint-disable-next-line no-restricted-globals, no-alert
    if (confirm("Are you sure you want to sign out?")) {
        AccessToken.set("");
        AccessTokenSecret.set("");
        DraftMessages.set([]);
        Name.set("");
        OAuthSecret.set("");
        PendingMessages.set([]);
        ProfileImageUrl.set("");
        ScreenName.set("");
        SentMessages.set(0);
        UserId.set("");
        localStorage.clear();
        window.location.replace("/login");
    }
}
