import { toast } from "@zerodevx/svelte-toast";
import config from "../../config";
export async function callAPI(path, initialOptions, requestBody) {
    const options = initialOptions;
    try {
        // Serialise the body if appropriate
        if (requestBody) {
            if (!options.headers) {
                options.headers = {};
            }
            options.body = JSON.stringify(requestBody);
            options.headers["Content-Type"] = "application/json";
        }
        // Deal with it
        const response = await fetch(`${config.baseApiUrl}${path}`, options);
        if (!response.ok) {
            console.error(`Could not access ${path} cloud function: `, response);
            throw new Error(`Error ${response.status} from ${path} cloud function`);
        }
        // Inspect the headers in the response
        if (response.headers.get("content-type") === "application/json") {
            const responseBody = (await response.json());
            return responseBody;
        }
        return undefined;
    }
    catch (err) {
        if (!options.notoast) {
            toast.push(err.message);
        }
        throw err;
    }
}
