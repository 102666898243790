<script lang="ts">
  import { useLocation, useFocus } from "svelte-navigator";
  import LoginStatus from "./LoginStatus.svelte";
  import Buffer from "./Buffer.svelte";
  import NewTweet from "./NewTweet.svelte";
  import { UserId } from "../services/store";

  const location = useLocation();
  const registerFocus = useFocus();

  if ($location.pathname !== "/login" && $location.pathname !== "/twitter-callback" && !$UserId) {
    window.location.replace("/login");
  }
</script>

{#if $location.pathname === "/twitter-callback" || $UserId}
  <main>
    <LoginStatus />
    <Buffer />
    <NewTweet />
  </main>
{:else}
  <h1 class="visuallyHidden" use:registerFocus>The blog post is being loaded...</h1>
{/if}

<style>
  main {
    text-align: center;
    padding: 1em;
    margin: 0 auto;
  }

  .visuallyHidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }

  @media (min-width: 640px) {
    main {
      max-width: none;
    }
  }
</style>
